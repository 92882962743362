import { AccessCondition } from '@/jbi-shared/types/access-condition.interface';
import { EntitiesWithPermissionsMatrix } from '@/jbi-shared/types/entities-with-permissions-matrix.interface';
import { ApiState } from '@/store/types/general.types';
import { Group } from '../../admin/types/admin.types';
import { ProductInvitationDto } from '../../application/types/application.types';
import {
  EntityTypes,
  ModuleActionTypes
} from '../../module-tree/enums/module-tree.enums';
import {
  ModuleSubdivision,
  ModuleTree
} from '../../module-tree/types/module-tree.types';
import { User } from '../../subscription/subscription.state';

export interface RolesAndPermissionApiState {
  getModules: ApiState;
  getPermissionsForResource: ApiState;
  getRolesAndExceptionsForResource: ApiState;
  addUsersToRole: ApiState;
  addGroupsToRole: ApiState;
  getExceptionsByModule: ApiState;
  getResourceExceptionsByModule: ApiState;
  getResourceInstancesExceptions: ApiState;
  getGroupExceptions: ApiState;
  createException: ApiState;
  updateException: ApiState;
  deleteExceptions: ApiState;
  getPermissionsForUsers: ApiState;
  getPermissionsForGroups: ApiState;
  getAccessConditionsForEntity: ApiState;
  getFilteredPermissionsGroupByUserId: ApiState;
}

export interface RolesAndPermissionsState {
  apiState: RolesAndPermissionApiState;
  modules: ModuleTreeRecord[] | undefined;
  permissionsForResource: GetPermissionsResponse | undefined;
  rolesAndExceptionsForResource: RolesAndExceptionsResponse | undefined;
  moduleExceptions: ModuleExceptionsResponse | undefined;
  resourceExceptions: Role | undefined;
  groupExceptions: ResourceExceptions | undefined;
  selectedGroupsForVueTreeselect: SelectedGroupsForVueTreeselect[] | undefined;
  resourceInstancesExceptions: ResourceExceptionDto[] | undefined;
  permissionsForUsers: EntitiesWithPermissionsMatrix | undefined;
  permissionsForGroups: EntitiesWithPermissionsMatrix | undefined;
  currentEntityInView: string | undefined;
  accessConditionsForEntity: AccessCondition | undefined;
  filteredPermissionsGroupByUserId:
    | FilteredPermissionsGroupByUserIdResponse
    | undefined;
}

export interface ModuleTreeRecord {
  id: number;
  parent: number;
  parentId: number;
  name: string;
  label: string;
  description: string;
  dropdown: boolean;
  entityType: string;
  hasInstances: boolean;
  action: ModuleActionTypes;
  level: number;
  subdivisions?: ModuleSubdivision[];
  hasSubmodules?: boolean; // Purely for frontend use to check module has the submodules
}

export interface FilteredPermissionsGroupByUserIdResponse {
  groups: number[];
}

export interface Role extends ModuleTree {
  checked: boolean;
  submodules: Role[];
  instanceIds: number[];
  exception?: ModuleExceptionTypes;
  permission?: ModulePermissionTypes;
}

export interface RoleEntity {
  id: number;
  slug: string;
  name: string;
  description: string;
  status: RoleStatusEnum;
}

export interface GetPermissionsResponse {
  role: Role[];
  isSuperAdmin?: boolean;
}

export interface RoleScopeDto {
  type: string;
  scope: Group | ModuleSubdivision;
}

export interface RoleDto {
  role: RoleEntity;
  roleScopes: RoleScopeDto[] | null;
  roleTree: Role[];
  assignedVia?: Group | null;
}
export interface RolesAndExceptionsResponse {
  roles: RoleDto[];
  exceptions: Array<{
    roleTree: Role;
    instance?: Group;
    assignedVia?: Group | null;
  }>;
}

export interface PermissionHelperPayload {
  resourceType: ResourceTypes;
  resourceId: number;
  moduleName: string;
  instanceId?: number;
  instanceType?: EntityTypes;
}

export interface PermissionHelperRolesAndExceptionPayload {
  resourceType: ResourceTypes;
  resourceId: number;
  moduleName: string;
  instanceType?: EntityTypes; // here InstanceTypes are equal to EntityTypes.
  instanceIds?: number[];
}

export interface AddUsersToRoleDto {
  existingUserIds: number[];
  invitations?: ProductInvitationDto[];
}

export interface GroupIdTypeName {
  groupId: number;
  typeName: string;
}

export interface AddGroupsToRoleDto {
  payload: GroupIdTypeName[];
}
export interface CreateExceptionsPayload {
  resourceType: ResourceTypes;
  resourceId: number;
  moduleName: string;
  resourceExceptions: Array<{
    moduleTree: Role;
    instanceType?: EntityTypes; // here InstanceTypes are equal to EntityTypes.
    instanceId?: number;
  }>;
}

export interface UpdateExceptionPayload {
  resourceType: ResourceTypes;
  moduleTree: Role;
  resourceId: number;
  instanceType?: EntityTypes; // here InstanceTypes are equal to EntityTypes.
  instanceIds?: number[];
}

export interface DeleteExceptionsPayload {
  resourceId: string;
  module: string;
}

export interface ModuleExceptionsResponse extends ModuleTreeRecord {
  resources: ResourceModuleWithExceptions[];
  instances: InstanceModuleWithExceptions[];
}

export interface ResourceModuleWithExceptions extends Role {
  resource: Group | User;
  resourceType: ResourceTypes;
}
export interface InstanceModuleWithExceptions {
  instance: Group;
  instanceResources: ResourceModuleWithExceptions[];
}

export interface ResourceExceptionDto {
  resourceId: string;
  instanceModule: string;
  action: PermissionsMatrixActionsEnum;
  permission: ModuleExceptionTypes;
  isException: boolean;
}

export interface ResourceExceptions {
  granted: string[];
  blocked: string[];
}

export interface FilteredExceptionPayload {
  instance: string;
  resource: string;
  groupType: string;
}

export interface SelectedGroupsForVueTreeselect {
  id: number;
  label: string;
}

export enum ResourceTypes {
  USER = 'user',
  GROUP = 'group'
}

export enum ModulePermissionTypes {
  ALLOW = 'allow',
  DENY = 'deny',
  MIXED = 'mixed',
  NONE = 'none'
}

export enum ModuleExceptionTypes {
  ALLOW = 'allow',
  DENY = 'deny',
  MIXED = 'mixed',
  NONE = 'none'
}

export enum RoleStatusEnum {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED'
}

export enum PermissionsMatrixActionsEnum {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}
