var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar custom-navbar",attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"navbar-brand"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../jbi-shared/assets/images/jbi_navbar_img@2x.png"),"width":"68.57","height":"30","alt":"jbi logo"}}),_c('div',{staticClass:"divider"},[_c('img',{attrs:{"src":require("../jbi-shared/assets/images/myjbi_modal_img.png"),"width":"68.57","height":"30","alt":"MyJBI logo"}})])]),_vm._m(0)],1),_c('div',{staticClass:"navbar-menu"},[_c('div',{staticClass:"navbar-start navbarItemContainer"},[_c('router-link',{class:[
          'navbar-item',
          _vm.isMissingAttributeValue ? 'navItemDisabled' : 'navItem'
        ],attrs:{"to":"/products"}},[_vm._v(" PRODUCTS ")]),_c('router-link',{class:[
          'navbar-item',
          _vm.isMissingAttributeValue ? 'navItemDisabled' : 'navItem'
        ],attrs:{"to":"/orders"}},[_vm._v(" MY ORDERS ")]),_c('router-link',{class:[
          'navbar-item',
          _vm.isMissingAttributeValue ? 'navItemDisabled' : 'navItem',
          _vm.$route.path === '/account' ? 'isNavActive' : ''
        ],attrs:{"to":{ path: '/account', query: { tab: 'profile' } }}},[_c('div',[_vm._v("ACCOUNT")])]),(_vm.hasAccessToJournals)?_c('router-link',{class:[
          'navbar-item',
          _vm.isMissingAttributeValue ? 'navItemDisabled' : 'navItem'
        ],attrs:{"to":"/journals"}},[_c('div',[_vm._v("JOURNALS")])]):_vm._e()],1),_c('div',{staticClass:"navbar-end"},[_c('div',{class:['navbar-item', 'navItem']},[_c('div',{style:('width: 100px;display: flex;justify-content: flex-end;')},[_vm._t("main")],2)])])]),_c('div',{staticClass:"loadingBar",attrs:{"id":"loading-bar"}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"navbar-burger",attrs:{"role":"button","aria-label":"menu","aria-expanded":"false"}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }